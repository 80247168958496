import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import BottomCta from '../../components/bottom-cta'

// Load Page Content
import contentEn from '../../../content/pages/products/jascloud.en.yaml'
import contentId from '../../../content/pages/products/jascloud.id.yaml'
import OtherProducts from '../../components/other-products'
import StudyCaseSlider, { AllStudyCaseType } from '../../components/study-case'
import SEO from '../../components/seo'

export const query = graphql`
  query BusinessOutsourcingQuery($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "products"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    businessOutsourcingStudyCase: allStudyCasesVoice {
      edges {
        node {
          id
          languages {
            id
            description
            locale
            logo
            title
          }
        }
      }
    }
    heroFigure: file(relativePath: { eq: "images/business-outsourcing/figure-hero.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 95)
      }
    }
    whyJasnitaFigure: file(relativePath: { eq: "images/business-outsourcing/figure-why-jasnita.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 95)
      }
    }
    pointIcon: file(relativePath: { eq: "images/business-outsourcing/point.svg" }) {
      id
      publicURL
    }
    focusGoalsIcon: file(relativePath: { eq: "images/business-outsourcing/icon-focus-goals.svg" }) {
      id
      publicURL
    }
    saveExtraIcon: file(relativePath: { eq: "images/business-outsourcing/icon-save-extra.svg" }) {
      id
      publicURL
    }
    weManageIcon: file(relativePath: { eq: "images/business-outsourcing/icon-we-manage.svg" }) {
      id
      publicURL
    }
    whyJasnita01Icon: file(relativePath: { eq: "images/business-outsourcing/icon-why-jasnita-01.svg" }) {
      id
      publicURL
    }
    whyJasnita02Icon: file(relativePath: { eq: "images/business-outsourcing/icon-why-jasnita-02.svg" }) {
      id
      publicURL
    }
    whyJasnita03Icon: file(relativePath: { eq: "images/business-outsourcing/icon-why-jasnita-03.svg" }) {
      id
      publicURL
    }
  }
`

interface BusinessOutsourcingPagePropsType {
  data: {
    businessOutsourcingStudyCase: AllStudyCaseType
    pointIcon: PublicUrlType
    heroFigure: IGatsbyImageData
    focusGoalsIcon: PublicUrlType
    saveExtraIcon: PublicUrlType
    weManageIcon: PublicUrlType
    whyJasnita01Icon: PublicUrlType
    whyJasnita02Icon: PublicUrlType
    whyJasnita03Icon: PublicUrlType
    whyJasnitaFigure: IGatsbyImageData
  }
}

const BusinessOutsourcingPage = (props: BusinessOutsourcingPagePropsType): JSX.Element => {
  const { t, i18n } = useTranslation()
  const [content, setContent] = useState(contentEn)
  const {
    businessOutsourcingStudyCase,
    pointIcon,

    focusGoalsIcon,
    saveExtraIcon,
    weManageIcon,
    whyJasnita01Icon,
    whyJasnita02Icon,
    whyJasnita03Icon,
  } = props.data

  const heroFigure = getImage(props.data.heroFigure)
  const whyJasnitaFigure = getImage(props.data.whyJasnitaFigure)

  useEffect(() => {
    switch (i18n.language) {
      case 'id':
        setContent(contentId)
        break
      case 'en':
        setContent(contentEn)
        break
      default:
        setContent(contentEn)
        break
    }
  }, [i18n.language])

  return (
    <>
      <SEO title={content.title} description={content.description} lang={i18n.language}></SEO>
      {/* Start Hero Section */}
      <section className="uk-light">
        <div
          className="uk-section uk-background-norepeat uk-background-cover tm-business-oursourcing-hero-background uk-flex uk-flex-middle"
          uk-height-viewport=""
          uk-img=""
          style={{ minHeight: 'calc(100vh)' }}
        >
          <div className="uk-width-1-1">
            <div className="uk-container">
              <div className="uk-flex uk-flex-between" uk-grid="">
                <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                  <div className="uk-animation-slide-top-medium uk-text-center uk-text-left@m">
                    {/* <img className="uk-hidden@m" width="125" alt="" uk-img={logo.publicURL} /> */}
                    <h1 className="uk-heading-small">
                      Indonesia’s most advanced and reliable call center outsourcing vendor
                    </h1>
                    <p className="uk-text-lead">Go forward with us for your great business much better</p>
                  </div>
                </div>
                <div className="uk-width-1-2@m uk-flex uk-flex-right uk-visible@m">
                  {heroFigure && <GatsbyImage image={heroFigure} alt="Business Outsourcing" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero Section */}

      <section className="uk-section">
        <div className="uk-with-1-1">
          <div className="uk-container">
            <div className="uk-flex-middle" uk-grid="">
              <div className="uk-width-1-2@m">
                <div className="uk-flex uk-flex-center uk-text-center">
                  <h1>
                    "In the growing digital" space, it is essential for every business to have a proactive customer
                    service support and digital sales strategy
                  </h1>
                </div>
              </div>
              <div
                className="uk-width-1-2@m"
                uk-scrollspy="target: > *; cls: uk-animation-slide-right-small; delay: 100"
              >
                <h2>Why choose to outsource?</h2>
                <p>
                  Having an in-house customer service and contact center is very costly. Maintaining dedicated
                  facilities, conducting professional trainings, and dealing with administrative hassle requires a large
                  team and specialized skillsets.
                </p>
                <div
                  className="uk-grid-small uk-margin-large-top "
                  uk-grid=""
                  uk-height-match="target: > div > .uk-card"
                  // uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 100"
                >
                  <div className="uk-width-1-3@m">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                      <img src={focusGoalsIcon.publicURL} alt="" uk-img="" width={80} />
                      <h3>Save Extra Cost</h3>
                    </div>
                  </div>
                  <div className="uk-width-1-3@m">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                      <img src={saveExtraIcon.publicURL} alt="" uk-img="" width={80} />
                      <h3>We Manage Professional</h3>
                    </div>
                  </div>
                  <div className="uk-width-1-3@m">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                      <img src={weManageIcon.publicURL} alt="" uk-img="" width={80} />
                      <h3>Focus Goals Business</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section">
        <div className="uk-with-1-1">
          <div className="uk-container">
            <div className="uk-width-1-1 uk-text-center uk-margin-large-bottom">
              <h2>Our Solution</h2>
            </div>
            <div className="uk-grid uk-child-width-1-3@m uk-flex-center" uk-grid="">
              <div className="uk-flex">
                <div className="uk-width-auto uk-margin-right">
                  <img width={25} src={pointIcon.publicURL} style={{ marginTop: 4 }} />
                </div>
                <div className="uk-width-expand">
                  <h3>Administrative back office</h3>
                  <ul>
                    <li>Data Entry</li>
                    <li>Analysis</li>
                    <li>Compilation of information</li>
                  </ul>
                </div>
              </div>
              <div className="uk-flex">
                <div className="uk-width-auto uk-margin-right">
                  <img width={25} src={pointIcon.publicURL} style={{ marginTop: 4 }} />
                </div>
                <div className="uk-width-expand">
                  <h3>Emergency Response</h3>
                  <p>Trained and skilled responders for speed and accuracy</p>
                </div>
              </div>
              <div className="uk-flex">
                <div className="uk-width-auto uk-margin-right">
                  <img width={25} src={pointIcon.publicURL} style={{ marginTop: 4 }} />
                </div>
                <div className="uk-width-expand">
                  <h3>Telemarketing & Tele sales </h3>
                  <p>Lead generation and solutions to increase your market reach</p>
                </div>
              </div>
              <div className="uk-flex">
                <div className="uk-width-auto uk-margin-right">
                  <img width={25} src={pointIcon.publicURL} style={{ marginTop: 4 }} />
                </div>
                <div className="uk-width-expand">
                  <h3>Fault Reporting/ Technical Support</h3>
                  <p>Provide complaint handling, coordination, escalation, and troubleshooting</p>
                </div>
              </div>
              <div className="uk-flex">
                <div className="uk-width-auto uk-margin-right">
                  <img width={25} src={pointIcon.publicURL} style={{ marginTop: 4 }} />
                </div>
                <div className="uk-width-expand">
                  <h3>Customer Service</h3>
                  <p>
                    Handle any inbound requests and issues with standardized tone and professional SOP (standard
                    procedure) to drive great customer satisfaction and loyalty
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section">
        <div className="uk-with-1-1">
          <div className="uk-container">
            <div uk-grid="">
              <div className="uk-width-1-2@m">
                <div className="uk-flex uk-flex-center uk-text-center">
                  <div className="uk-width-expand@m">
                    {whyJasnitaFigure && <GatsbyImage image={whyJasnitaFigure} alt="Why Jasnita" />}
                  </div>
                </div>
              </div>
              <div
                className="uk-width-1-2@m"
                uk-scrollspy="target: > *; cls: uk-animation-slide-right-small; delay: 100"
              >
                <h2>Why Jasnita?</h2>
                <div
                  className="uk-grid-small uk-margin-large-top "
                  uk-grid=""
                  uk-height-match="target: > div > .uk-card"
                  // uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 100"
                >
                  <div className="uk-width-1-1 uk-first-column">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                      <div className="uk-flex">
                        <img src={whyJasnita01Icon.publicURL} alt="" uk-img="" />
                        <h4 className="uk-margin-remove">
                          We help reduce operational, infrastructure, and maintenance cost for a contact center
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-1 uk-first-column">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                      <div className="uk-flex">
                        <img src={whyJasnita02Icon.publicURL} alt="" uk-img="" />
                        <h4 className="uk-margin-remove">Very experienced and professional team to maximize results</h4>
                      </div>
                    </div>
                  </div>
                  <div className="uk-width-1-1 uk-first-column">
                    <div className="uk-card uk-card-default uk-card-small uk-card-body">
                      <div className="uk-flex">
                        <img src={whyJasnita03Icon.publicURL} alt="" uk-img="" />
                        <h4 className="uk-margin-remove">
                          Jasnita have proprietary and in-house development team to customize our technology to suit
                          your needs
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <StudyCaseSlider data={businessOutsourcingStudyCase} language={i18n.language} />
      </section>

      <BottomCta color="#0C73D2" />
      <OtherProducts products={content.other_product} />
    </>
  )
}

export default BusinessOutsourcingPage
